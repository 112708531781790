import React, {useContext} from "react";
import SchoolItiParameters from "@/models/SchoolItiParameters";

const SettingsContext = React.createContext<SchoolItiParameters | null>(null);

export function useSettingsContext(): SchoolItiParameters {
    const context = useContext(SettingsContext);

    if (context == null) {
        throw new Error("useSettingsContext must be used within a SettingsContextProvider");
    }

    return context;
}

export default SettingsContext;
