import React, {VoidFunctionComponent} from "react";
import Page from "@/components/common/ui/Page";
import {useSettingsContext} from "@/components/common/settings/SettingsContext";
import {Box, Button, Grid, Paper} from "@mui/material";
import {Link} from "react-router-dom";
import Routes from "@/Routes";
import HeaderMenu from "@/components/common/ui/HeaderMenu";
import {useDisplay} from "@/components/common/ui/DisplayContext";
import {styled} from "@mui/system";
import Carousel from "react-material-ui-carousel";
import Images from "@/components/common/styles/Images"

const PublicHomeRNA: VoidFunctionComponent<never> = () => {
    const {SCHOLAR_YEAR: schoolYear, SUBSCRIPTIONS_OPEN: subscriptionsOpen} = useSettingsContext();

    const {isMobile} = useDisplay();

    const infoItems = [
        (
            <Box textAlign={"center"} key={"a"}>
                <img src={Images.bus} alt={"Bus logo"} style={{height: 160, maxWidth: "100%"}}/>
                <SmallTitle>Un compte unique</SmallTitle>
                <span>pour toutes vos démarches en Nouvelle-Aquitaine.</span>
            </Box>
        ),
        (
            <Box textAlign={"center"} key={"b"}>
                <img src={Images.helper1} alt={"Inscription"} style={{height: 160, maxWidth: "100%"}}/>
                <SmallTitle>Une saisie simplifiée</SmallTitle>
                <span>pour vous faire gagner du temps !</span>
            </Box>
        ),
        (
            <Box textAlign={"center"} key={"c"}>
                <img src={Images.subscriptionSuccessfull} alt={"Inscription success"} style={{
                    height: 160,
                    maxWidth: "100%",
                }}/>
                <SmallTitle>Un suivi en ligne</SmallTitle>
                <span>de vos demandes d'inscription.</span>
            </Box>
        ),
    ];

    return (
        <main className="main-background">
            <Page>
                <Page.Header>
                    <HeaderMenu noBurger/>
                </Page.Header>

                <Page.Body>
                    <Grid container sx={{py: (theme) => theme.default.spacing}}>
                        <Grid item xs={12}>
                            <Box sx={{px: (theme) => isMobile ? theme.default.spacing : 0}}>
                                <h1>Bienvenue,</h1>
                                <P>
                                    Sur le site d'inscription aux transports scolaires de la Région Nouvelle-Aquitaine
                                    pour
                                    la rentrée <span style={{fontWeight: "bold"}}>{schoolYear}/{schoolYear + 1}</span>
                                </P>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper elevation={isMobile ? 0 : 3} sx={{
                                px: (theme) => theme.default.spacing,
                                py: (theme) => isMobile ? 0 : theme.default.spacing,
                            }}>
                                <SmallTitle style={{marginTop: 0}}>
                                    Un espace personnel pour l'inscription aux transports scolaires
                                </SmallTitle>

                                {isMobile ?
                                    <Carousel autoPlay={false} animation={"slide"} height={260}>
                                        {infoItems}
                                    </Carousel> :
                                    <Grid container>
                                        {infoItems.map((item, index) => (
                                            <Grid item xs key={index}>
                                                {item}
                                            </Grid>
                                        ))}
                                    </Grid>
                                }
                            </Paper>
                        </Grid>
                        {!subscriptionsOpen &&
                            <Grid item xs={12} style={{textAlign: "center"}}>
                                <p>
                                    Les inscriptions scolaires pour
                                    l'année <span style={{fontWeight: "bold"}}>{schoolYear}/{schoolYear + 1}</span> sont
                                    fermées.
                                </p>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Paper
                                elevation={isMobile ? 0 : 3}
                                className={isMobile ? "top-shadow" : ""}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    p: (theme) => theme.default.spacing,
                                }}
                            >
                                <Box component={"h2"} sx={{color: "primary.main", mt: 0}}>
                                    Connectez-vous à votre compte
                                </Box>
                                <Button component={Link} to={{pathname: Routes.HOME_AUTH}} style={{textAlign: "center"}}>
                                    Je me connecte{subscriptionsOpen && " ou je crée mon compte"}
                                </Button>
                                <Button component={Link} to={{
                                    pathname: Routes.TRAVELLER,
                                }} variant={"outlined"} sx={{mt: (theme) => theme.default.spacing}}>
                                    Je consulte les horaires
                                </Button>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{px: (theme) => theme.default.spacing}}>
                                Nota: La région Nouvelle-Aquitaine vous permet de bénéficier d'une réduction tarifaire à
                                partir du 3ème enfant inscrit aux transports scolaires.
                                <div style={{fontWeight: "bold"}}>
                                    Pour cela, vous devez
                                    inscrire, <span style={{textDecoration: "underline"}}>en même temps</span>,
                                    l'ensemble des enfants concernés pour l'année scolaire {schoolYear}-{schoolYear + 1}.
                                </div>
                                Avant de commencer l'inscription de vos enfants, munissez-vous
                                de <span style={{fontWeight: "bold"}}>votre dernier avis d'imposition </span>
                                pour vous permettre de bénéficier éventuellement d'une dégressivité tarifaire sur votre
                                participation familiale.
                                <p>
                                    Données personnelles :
                                </p>
                                <p>
                                    <a href="https://transports.nouvelle-aquitaine.fr/sites/default/files/2021-03/Informations%20concernant%20vos%20donn%C3%A9es%20personnelles_0.pdf" target="_blank" rel="noreferrer">Consulter
                                        les informations relatives au traitement de mes données personnelles</a> dans le
                                    cadre des services liés au transport scolaire, conformément au Règlement Général de
                                    Protection de Données.
                                </p>
                            </Box>
                        </Grid>
                    </Grid>
                </Page.Body>
            </Page>
        </main>
    );
};

const SmallTitle = styled("p")(({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textAlign: "center",
}));

const P = styled("p")(() => ({
    marginBottom: 0,
}));

export default PublicHomeRNA;
