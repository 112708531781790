import React, {FunctionComponent} from "react";
import {Box, Drawer, Modal, SxProps} from "@mui/material";
import {useDisplay} from "@/components/common/ui/DisplayContext";
import "./Popin.scoped.scss";

type PopinProps = {
    isOpen: boolean,
    onClose?: () => void,
    icon?: any,
    title?: string,
    fullHeight?: boolean,
    sx?: SxProps,
}

const Popin: FunctionComponent<PopinProps> = ({
    isOpen = false,
    onClose,
    icon = "",
    title = "",
    fullHeight = false,
    sx = {},
    children,
}) => {
    const {isDesktop} = useDisplay();

    return (
        <div>
            {!isDesktop &&
                <Drawer
                    className="popin-backdrop"
                    anchor={"bottom"}
                    open={isOpen}
                    onClose={onClose}
                    sx={sx}
                    PaperProps={{className: "top-shadow"}}
                >
                    <div className="popin-content">
                        <div style={{
                            textAlign: "center",
                        }}>
                            {/* encoche */}
                            <div style={{
                                width: "40px",
                                height: "3px",
                                flexGrow: 0,
                                borderRadius: "10px",
                                backgroundColor: "#e0e0e0",
                                margin: "10px auto 20px auto",
                            }}/>
                            {icon &&
                                <img src={icon} alt="" style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "29px",
                                }}/>
                            }
                            {title &&
                                <h2 style={{marginBottom: 0}}>{title}</h2>
                            }
                        </div>
                        <Box p={2}>
                            {children}
                        </Box>
                    </div>
                </Drawer>
            }
            {isDesktop &&
                <Modal
                    className="popin-backdrop"
                    open={isOpen}
                    onClose={onClose}
                    style={{
                        maxWidth: "600px",
                    }}
                >
                    <Box py={2} style={{
                        textAlign: "center",
                        display: "flex",
                        height: "100%",
                        outline: "none",
                    }}>
                        <div className="popin-content" style={{
                            borderRadius: "10px",
                            backgroundColor: "#FFF",
                            margin: "auto",
                            display: "inline-block",
                            textAlign: "initial",
                            height: fullHeight ? "100%" : undefined,
                        }}>
                            <div className={"flex-wrapper-column"}>
                                <div style={{
                                    textAlign: "center",
                                }}>
                                    {icon &&
                                        <img src={icon} alt="" style={{
                                            width: "40px",
                                            height: "40px",
                                            borderRadius: "29px",
                                            marginTop: "20px",
                                        }}/>
                                    }
                                    {title &&
                                        <h2 style={{
                                            marginLeft: "20px",
                                            marginRight: "20px",
                                            marginBottom: 0,
                                        }}>{title}</h2>
                                    }
                                </div>
                                <Box p={2} className={fullHeight ? "flex-fill" : undefined}>
                                    {children}
                                </Box>
                            </div>
                        </div>
                    </Box>
                </Modal>
            }
        </div>
    );
};

export default Popin;