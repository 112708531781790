import React from "react";
import {Button} from "@mui/material";
import {useSettingsContext} from "@/components/common/settings/SettingsContext";
import "./LegalMentions.scoped.scss";

function LegalMentions() {

    const {LEGAL_MENTION_SUBSCRIPTION_FORM: legalMentionUrl} = useSettingsContext();
    const appVersion = "V1.0";

    const goToLegalMention = () => {
        window.open(legalMentionUrl);
    };

    return (
        <div className="legal-mentions">
            <Button variant="text" color="inherit" onClick={goToLegalMention} className="menu-item"><strong>Vos
                données</strong></Button>
            <span className="menu-version">{appVersion}</span>
        </div>
    );
}

export default LegalMentions;