import {format as _format, lastDayOfMonth, parse} from "date-fns";
import {fr} from "date-fns/locale";

const FORMAT_ISO_DATE = "yyyy-MM-dd";

const FORMAT_DISPLAY_DATE = "P";

const FORMAT_DISPLAY_TEXT_DATE = "dd MMMM yyyy";

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
/**
 * @param   {Date|number} date
 * @param   {string}      formatStr
 * @returns {string}
 * @see module:date-fns.format
 */
const format = (date, formatStr = "PP") => {
    return _format(date, formatStr, {
        locale: fr,
    });
};

const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
};

const displayDate = (date) => {
    if (date === undefined) {
        date = new Date();
    }
    if (!(date instanceof Date)) {
        try {
            date = parse(date, FORMAT_ISO_DATE, new Date());
        } catch (err) {
            date = new Date();
        }
    }
    try {
        return format(date, FORMAT_DISPLAY_DATE);
    } catch (err) {
        console.error("Erreur lors de l'affichage de la date : ", err);
        return format(new Date(), FORMAT_DISPLAY_DATE);
    }
};

const displayFullTextDate = (date) => {
    if (!(date instanceof Date)) {
        date = parse(date, FORMAT_ISO_DATE, new Date());
    }
    return format(date, FORMAT_DISPLAY_TEXT_DATE);
};

const parseDate = (dateStr) => {
    return parse(dateStr, FORMAT_ISO_DATE, new Date());
};

const getSecondToLastDayOfMonth = (date) => {
    return new Date(lastDayOfMonth(date).setDate(lastDayOfMonth(date).getDate() - 1));
};

export {
    FORMAT_ISO_DATE,
    FORMAT_DISPLAY_DATE,
    FORMAT_DISPLAY_TEXT_DATE,
    format,
    isValidDate,
    displayDate,
    displayFullTextDate,
    parseDate,
    getSecondToLastDayOfMonth,
};
