import AbstractService from "./AbstractService";
// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
import {AxiosPromise} from "axios";
import {RegistrationStatusEnum, SubscriberQualitiesEnum} from "frontend.common/src/SubscriptionConstants";
import {AnyObject} from "@/types/main";
import DocumentType from "@/models/DocumentType";

export interface SubscriberRequest {
    uuid: string,
    subscriberId?: number,
    legalRepresentative: AnyObject,
    lastStepCode?: string,
    userActionRule?: string,
    content: AnyObject,
    // withStatus
    status?: string
    paymentStarted?: boolean,
    subscriberInfo?: AnyObject,
}

export interface SubscriberRequestContent {
    subscriber: AnyObject;
}

const SubscriberQualitiesConfig = {
    [SubscriberQualitiesEnum.DEMI_PENSIONNAIRE]: {label: "Demi-pensionnaire"},
    [SubscriberQualitiesEnum.INTERNE]: {label: "Interne"},
    [SubscriberQualitiesEnum.EXTERNE]: {label: "Externe"},
};

class SubscriberService extends AbstractService {

    constructor() {
        super("public/fiti/subscription/dynamic");
    }

    getAll(): AxiosPromise<SubscriberRequest[]> {
        return this.get("")
            .then(response => {
                response.data = response.data.map(subscriberRequest => {
                    subscriberRequest.content = JSON.parse(subscriberRequest?.content || "{}");
                    return subscriberRequest;
                });
                return response;
            });
    }

    registerSubscribers(subscriberIdsToRenew: number[], subscriberRequests: AnyObject[]) {
        return this.post("register", {
            subscriberIdsToRenew,
            subscriberRequests,
        });
    }

    getSubscriberRequest(subscriberUuid: string): AxiosPromise<SubscriberRequest> {
        return this.get([subscriberUuid]).then(response => {
            response.data.content = JSON.parse(response.data?.content || "{}");
            return response;
        });
    }

    /**
     *
     * @param   {string}                    requestUUID
     * @param   {string}                    currentUserAction
     * @param   {object}                    content
     * @returns {Promise<AxiosResponse<*>>}
     */
    postCurrentStep(requestUUID: string, currentUserAction: string | undefined, content: AnyObject) {
        const params = {
            userAction: currentUserAction,
        };
        return this.post([requestUUID], content, {params: params});
    }

    setCurrentStep(requestUUID, step) {
        return this.put([requestUUID, "step"], {}, {params: {step: step}});
    }

    getQuotientFamilialAndCalculateTariffSection(numeroFiscal, studentQualityStatus) {
        const params = {
            numeroFiscal: numeroFiscal,
            studentQualityStatus: studentQualityStatus,
        };
        return this.get(["family-ratio"], {params: params});
    }

    /**
     *
     * @param   {string}                    subscriberRequestUUID
     * @returns {Promise<AxiosResponse<*>>}
     */
    finishSubscription(subscriberRequestUUID) {
        return this.post([subscriberRequestUUID, "finish"]);
    }

    cancelSubscription(subscriberRequestUUID) {
        return this.post([subscriberRequestUUID, "cancel"]);
    }

    getSubscriberRequestWithStatus(subscriberUuid) {
        return this.get([subscriberUuid, "with-status"]).then(response => {
            response.data.content = JSON.parse(response.data?.content || "{}");
            return response;
        });
    }

    getFamilyRatioAndTariffSectionInfo(studentQualityStatus) {
        const params = {studentQualityStatus: studentQualityStatus};
        return this.get("qf-and-tariff-section-info", {params: params});
    }

    isSubscriberRequestTrusteeWithReason(subscriberRequestUUID) {
        return this.get([subscriberRequestUUID, "is-trustee"]);
    }

    getSubscriberFareDetail(subscriberRequestUUID) {
        return this.get([subscriberRequestUUID, "fare-detail"]);
    }

    getMainFareFor(subscriberRequestUUID) {
        return this.get([subscriberRequestUUID, "main-fare"]);
    }

    getTariffSections() {
        return this.get<AnyObject[]>("tariff-section-list");
    }

    updateLRTariffSectionAndGetFareIfNeeded(subscriberRequestUUID: string, tariffSection: number) {
        const params = {
            tariffSection,
            subscriberRequestUUID,
        };
        return this.put("/tariff-section", {},{params: params});
    }

    getLRTariffSection(subscriberRequestUUID: string) {
        return this.get("/tariff-section", {
            params: {
                subscriberRequestUUID,
            },
        });
    }

    /* **********************************
     * 			    Utils
     * **********************************/

    getSubscriberQualityLabel(quality: string): string | undefined {
        return SubscriberQualitiesConfig[quality]?.label;
    }

    getRegistrationStatus(status: string): { code: string, label: string, variant: string } {
        let statusInfo;
        switch (status) {
            case RegistrationStatusEnum.REFUSED:
                statusInfo = {
                    label: "Refusé",
                    variant: "error",
                };
                break;
            case RegistrationStatusEnum.WAITING_FOR_ASSIGNMENT:
            case RegistrationStatusEnum.WAITING_FOR_EXAMINATION:
                statusInfo = {
                    label: "En traitement",
                    variant: "warning",
                };
                break;
            case RegistrationStatusEnum.WAITING_FOR_PAYMENT:
                statusInfo = {
                    label: "À payer",
                    variant: "warning",
                };
                break;
            case RegistrationStatusEnum.PRE_ASSIGNED:
            case RegistrationStatusEnum.FULLY_ASSIGNED:
                statusInfo = {
                    label: window._env_.REACT_APP_PROJECT === "RNA" ? "Payé" : "Accepté",
                    variant: "success",
                };
                break;
            case RegistrationStatusEnum.ACTIVATED:
            case RegistrationStatusEnum.WAITING_FOR_SUBSCRIPTIONS_CHANGES:
                statusInfo = {
                    label: "Activé",
                    variant: "success",
                };
                break;
            case RegistrationStatusEnum.WAITING_FOR_RENEWAL:
            case RegistrationStatusEnum.ARCHIVED:
                statusInfo = {
                    label: "Statut non géré: " + status,
                    variant: "error",
                };
                break;
            case RegistrationStatusEnum.WAITING_FOR_DOCUMENTS:
            default:
                statusInfo = {
                    label: "À compléter",
                    variant: "warning",
                };
                break;
        }

        statusInfo.code = status;

        return statusInfo;
    }

    getSubscriptionSummary(subscriberRequestUUID: string) {
        return this.getFile([subscriberRequestUUID, "subscription-summary"]);
    }

    getReviewRequest(subscriberId) {
        return this.get([subscriberId, "review-request"]);
    }

    getDocumentTypes(codes: string[]) {
        return this.get<DocumentType[]>("document-types", {params: {codes}});
    }

    updateLrComment(subscriberRequestUUID: string, lrComment: string) {
        return this.put([subscriberRequestUUID, "lr-comment"], lrComment)
    }
}

export default new SubscriberService();