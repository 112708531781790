import React from "react";
import Icon from "@/components/common/ui/Icon";
import {Box} from "@mui/material";
import {BoxProps} from "@mui/material/Box/Box";

export type InfoBoxProps = BoxProps & {
    logo?: any,
    className?: string,
    center?: boolean,
};

const InfoBox: React.FC<InfoBoxProps> = ({
    logo = null,
    className = "",
    center = false,
    children,
    ...props
}) => {

    let finalClassName = "tooltip";
    if (className?.trim()) {
        finalClassName += " " + className.trim();
    }
    return (
        <Box className={finalClassName} {...props}>
            {logo && <Icon src={logo}/>}

            <div className="tooltip-text" style={{
                textAlign: center ? "center" : "left",
            }}>
                {children}
            </div>
        </Box>
    );
};

export default InfoBox;