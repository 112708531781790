import React, {FunctionComponent} from "react";
import {Button, ButtonProps} from "@mui/material";
import {Link} from "react-router-dom";

export type ButtonLinkProps = Omit<ButtonProps<Link>, "component">;

const ButtonLink: FunctionComponent<ButtonLinkProps> = (props) => {

    return (
        <Button {...props} component={Link}/>
    );
};

export default ButtonLink;
