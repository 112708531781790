import warning from "@/assets/img/default/icons/warning.svg";
import close from "@/assets/img/default/icons/close.svg";
import chevronLeft from "@/assets/img/default/icons/chevron-left.svg";
import chevronDown from "@/assets/img/default/icons/chevron-down.svg";
import chevronUp from "@/assets/img/default/icons/chevron-up.svg";
import homeFlat from "@/assets/img/default/icons/home-flat.svg";
import logoSmall from "@/assets/img/default/icons/logo-small.svg";
import files from "@/assets/img/default/icons/files.svg";
import success from "@/assets/img/default/icons/success.svg";
import failure from "@/assets/img/default/icons/failure.svg";
import downloadFile from "@/assets/img/default/icons/download-file.svg";
import childAvatar from "@/assets/img/default/icons/child-avatar.svg";
import pencilEdit from "@/assets/img/default/icons/pencil-edit.svg";
import bus from "@/assets/img/default/icons/bus.png";
import addChild from "@/assets/img/default/icons/add-child.svg";
import chevronRightRed from "@/assets/img/default/icons/chevron-right-red.svg";
import chevronRight from "@/assets/img/default/icons/chevron-right.svg";
import user from "@/assets/img/default/icons/user.svg";
import fileUser from "@/assets/img/default/icons/file-user.svg";
import options from "@/assets/img/default/icons/options.svg";
import folder from "@/assets/img/default/icons/folder.svg";
import chatMessage from "@/assets/img/default/icons/chat-message.svg";
import archive from "@/assets/img/default/icons/archive.svg";
import burgerMenu from "@/assets/img/default/icons/burger-menu.svg";
import markerSelected from "@/assets/img/default/icons/marker-selected.svg";
import markerUnselected from "@/assets/img/default/icons/marker-unselected.svg";
import home from "@/assets/img/default/icons/home.svg";
import building from "@/assets/img/default/icons/building.png";
import homeMap from "@/assets/img/default/icons/home-map.png";
import creditCard from "@/assets/img/default/icons/credit-card.svg";
import paper from "@/assets/img/default/icons/paper.svg";
import circle from "@/assets/img/default/icons/cercle.png";
import incomplete from "@/assets/img/default/icons/incomplete.svg";
import markerOutlined from "@/assets/img/default/icons/marker-outlined.png";
import logoutIcon from "@/assets/img/default/icons/logout-icon.svg";
import questionMark from "@/assets/img/default/icons/question-mark.png";
import timer from "@/assets/img/default/icons/stopwatch_timer_running_time.svg";

import {IIcons} from "./Icons";

let logo;
let position;
let placeholder;

const Icons: IIcons = {
    warning,
    close,
    chevronLeft,
    chevronDown,
    chevronUp,
    homeFlat,
    logoSmall,
    files,
    success,
    failure,
    downloadFile,
    childAvatar,
    pencilEdit,
    bus,
    addChild,
    chevronRightRed,
    chevronRight,
    logo,
    user,
    fileUser,
    options,
    folder,
    chatMessage,
    archive,
    burgerMenu,
    markerSelected,
    markerUnselected,
    home,
    building,
    homeMap,
    position,
    placeholder,
    creditCard,
    paper,
    circle,
    incomplete,
    markerOutlined,
    logoutIcon,
    questionMark,
    timer,
};

export default Icons;