import AbstractService, {CustomAxiosRequestConfig} from "./AbstractService";
import {AnyObject, Dictionary} from "@/types/main";
import {dataURItoFile} from "@/utils/FilesUtils";
import DocumentType from "@/models/DocumentType";

class SubscriberFilesService extends AbstractService {

    constructor() {
        super("public/fiti/subscription/dynamic");
    }

    getSubscriberRequestPicture(subscriberRequestUUID: string, config?: CustomAxiosRequestConfig) {
        return this.getFile([subscriberRequestUUID, "picture"], undefined, config);
    }

    getFileBlob(subscriberRequestUUID: string, id: number, config?: CustomAxiosRequestConfig) {
        return this.getFile([subscriberRequestUUID, "files", id, "file"], undefined, config);
    }

    postSubscriberRequestPicture(subscriberRequestUUID: string, pictureUri: string) {
        return this.postFiles(subscriberRequestUUID, [dataURItoFile(pictureUri)], "PICTURE");
    }

    getFiles(subscriberRequestUUID: string, config?: CustomAxiosRequestConfig) {
        return this.get<AnyObject[]>([subscriberRequestUUID, "files"], config);
    }

    postFiles(subscriberRequestUUID: string, files: File[], documentTypeCode: string) {
        let formData = new FormData();
        if (files.length > 0) {
            for (const file of files) {
                formData.append("files[]", file, encodeURIComponent(file.name));
            }
        }
        return this.post(
            [subscriberRequestUUID, "files"],
            formData,
            {
                params: {
                    documentTypeCode,
                },
            }
        );
    }

    postFilesByDocumentTypeCode(subscriberRequestUUID: string, filesByDocumentTypeCodes: Dictionary<File[]>) {
        const promises: Promise<any>[] = [];

        for (const [documentTypeCode, files] of Object.entries(filesByDocumentTypeCodes)) {
            if (files.length > 0) {
                promises.push(this.postFiles(subscriberRequestUUID, files, documentTypeCode));
            }
        }

        return Promise.all(promises);
    }

    deleteAttachedFiles(subscriberRequestUUID: string, id: number) {
        return this.delete<void>([subscriberRequestUUID, "files", id]);
    }

    getRequiredDocumentTypes(subscriberRequestUUID: string, remainingOnly: boolean, includeOther: boolean = false) {
        return this.get<DocumentType[]>([subscriberRequestUUID, "files", "required-document-types"], {
            params: {
                includeOther,
                remainingOnly,
            },
        });
    }

}

export default new SubscriberFilesService();