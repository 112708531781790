// react
import {createRef, useMemo} from "react";
import {HashRouter, matchPath, Switch} from "react-router-dom";

// mui
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {LocalizationProvider} from "@mui/lab";
import {ThemeProvider, useMediaQuery} from "@mui/material";
import {SnackbarProvider} from "notistack";
import {ConfirmDialogProvider} from "react-mui-confirm";

// date localization
import fr from "date-fns/locale/fr";

// Style
import theme from "@/theme";

// Main components
import {ROUTES_ARRAY} from "@/Routes";
import {LoaderProvider} from "@/components/common/ui/LoaderContext";
import {DisplayContext} from "@/components/common/ui/DisplayContext";

// Sentry
import {BrowserTracing} from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import {createBrowserHistory} from "history";
import RoutesComponent from "@/RoutesComponent";
import SentryRoute from "@/SentryRoute";
import NotFoundPage from "@/components/NotFoundPage";
import store from "@/utils/Store";

const history = createBrowserHistory();

const sentryEnv = window._env_.REACT_APP_SENTRY_ENV;
const sentryDsn = window._env_.REACT_APP_SENTRY_DSN;
const sentryToken = window._env_.REACT_APP_SENTRY_TOKEN;
if (["rec", "preprod", "prod"].includes(sentryEnv) && sentryDsn && sentryToken) {
    Sentry.init({
        dsn: sentryDsn,
        token: sentryToken,
        environment: sentryEnv,
        release: process.env.REACT_APP_VERSION,
        integrations: [new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, ROUTES_ARRAY, matchPath),
        })],
        tracesSampleRate: 1.0,
    });
}

function App() {
    const notistackRef = createRef();

    const isDesktop = useMediaQuery("(min-width:900px)");

    const displayModes = useMemo(() => ({
        isDesktop: isDesktop,
        isMobile: !isDesktop,
    }), [isDesktop]);

    let computedBaseName;
    if (window._env_.REACT_APP_PROJECT === "RNA") {
        computedBaseName = window._env_.REACT_APP_APPLICATION_SUB_PATH;
    } else if (!window._env_.REACT_APP_DEFAULT_COMMON_CODE) {
        computedBaseName = `/${store.commonCode}`;
    }

    return (
        <Sentry.ErrorBoundary>
            <DisplayContext.Provider value={displayModes}>
                <ThemeProvider theme={theme}>
                    <LoaderProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
                            <HashRouter basename={computedBaseName}>
                                <SnackbarProvider ref={notistackRef} dense={!isDesktop}>
                                    <ConfirmDialogProvider confirmButtonText={"Confirmer"} cancelButtonText={"Annuler"} cancelButtonProps={{variant: "outlined"}}>
                                        <Switch>
                                            <SentryRoute
                                                path={"/"}
                                                component={RoutesComponent}
                                            />
                                            <SentryRoute
                                                path={""}
                                                component={NotFoundPage}
                                            />
                                        </Switch>
                                    </ConfirmDialogProvider>
                                </SnackbarProvider>
                            </HashRouter>
                        </LocalizationProvider>
                    </LoaderProvider>
                </ThemeProvider>
            </DisplayContext.Provider>
        </Sentry.ErrorBoundary>
    );
}

export default Sentry.withProfiler(App);
