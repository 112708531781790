import ImagesRNA from "./ImagesRNA";
import ImagesDefault from "./ImagesDefault";

export interface IImages {
    backgroundHeader: any;
    bill;
    bus: any;
    cbKo: any;
    cbOk: any;
    duplicataCheckIn: any;
    emailSent: any;
    logo: any;
    offlinePayment: any;
    okSuccessfulCheck: any;
    subscriptionInProgress: any;
    subscriptionSuccessfull: any;
    transportTicketPlaceholder: any;
    warning: any;
    helper1: any;
    helper2: any;
    helper3: any;
}

const Images: IImages = window._env_.REACT_APP_PROJECT === "RNA" ? ImagesRNA : ImagesDefault;

export default Images;