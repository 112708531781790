import {CSSProperties} from "react";
import {Property} from "csstype";
import theme from "@/theme";
import {ThemeColor} from "@/types/main";

type Size = "auto" | "large" | "medium" | "small";

export interface IconProps {
    src: string;
    alt?: string;
    size?: Size;
    color?: ThemeColor | Property.BackgroundColor;
    style?: CSSProperties;
}

const sizeToPx = (size?: Size) => {
    switch (size) {
        case "large":
            return 25;
        case "medium":
            return 20;
        case "small":
            return 15;
        default:
            return;
    }
};

const Icon = ({src, alt, size = "auto", color, style, ...otherProps}: IconProps) => {
    if(size === "auto" && color) {
        size = "medium";
    }

    const sizePx = sizeToPx(size);
    const _style = {
        width: sizePx,
        height: sizePx,
        ...(style || {}),
    };

    if(color) {
        const finalColor = Object.hasOwn(theme.palette, color) ? theme.palette[color].main : color;

        return (<span
            style={{
                ..._style,
                display: "inline-block",
                backgroundColor: finalColor,
                maskRepeat: 'no-repeat',
                maskSize: 'contain',
                maskPosition: 'center',
                maskImage: `url(${src})`,
            }}
            {...otherProps}
        />)
    } else {
        return (<img src={src} style={_style} alt={alt} {...otherProps}/>);
    }


};

export default Icon;