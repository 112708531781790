import React from "react";
import SettingsContext from "@/components/common/settings/SettingsContext";
import SchoolItiParametersService from "@/service/SchoolItiParametersService";
import useMemoAsync from "@/hooks/useMemoAsync";
import PaymentService from "@/service/PaymentService";
import SchoolItiParameters from "@/models/SchoolItiParameters";
import UtilityFunctions from "@/utils/UtilityFunctions";

function SettingsContextProvider({children}) {

    const settings = useMemoAsync<SchoolItiParameters | Error | undefined>(async () => {
        try {
            const logoUrl: string = SchoolItiParametersService.getLogoUrl();
            const tooltipUrl = SchoolItiParametersService.getTooltipUrl();

            const [
                parsedSettings,
                hasLogo,
                hasTooltip,
                {data: hasTreasuryOrmc},
            ] = await Promise.all([
                SchoolItiParametersService.getSettings(),
                UtilityFunctions.isImageExist(logoUrl),
                UtilityFunctions.isImageExist(tooltipUrl),
                PaymentService.hasTreasuryOrmc(),
            ]);

            return {
                ...parsedSettings,
                LOGO_IMAGE_URL: hasLogo ? logoUrl : undefined,
                TOOLTIP_IMAGE_URL: hasTooltip ? tooltipUrl : undefined,
                HAS_TREASURY_ORMC: hasTreasuryOrmc,
            };
        } catch (e) {
            console.log("Erreur lors du chargement des paramètres");
            return new Error();
        }
    }, []);

    if (!settings) {
        return null;
    }

    if (settings instanceof Error) {
        return (
            <div>
                <h2>Une erreur est survenue.</h2>
            </div>
        );
    }

    return (
        <SettingsContext.Provider value={settings}>
            {children}
        </SettingsContext.Provider>
    );
}

export default SettingsContextProvider;
