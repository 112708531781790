export interface CreditNote {
    id: number;
    uuid: string;
    /**
     * Datetime
     */
    createdDate: string;
    /**
     * Datetime
     */
    terminatedDate?: string;

    /**
     * @see PaymentTypeEnum
     */
    type: string;
    status: CreditNoteStatus;
    billingNumber: string;
    motif: string;
    refund: boolean;
    creditNoteRefunds: CreditNoteRefunds[];
}

export interface CreditNoteRefunds {
    id: number;
    amount: number;
    comment: string;
}

export enum CreditNoteStatus {
    ARCHIVED = "ARCHIVED",
    WAIT = "WAIT",
}