import {generatePath} from "react-router-dom";

const Routes = { // May be override by RoutesComponent.js for commonCode
    HOME_PUBLIC: "/",
    HOME_AUTH: "/home",
    REGISTER_LR: "/register",
    REGISTER_CHILDREN: "/register-children",
    CREATE_LR_ACCESS: "/create-access/:lrFormId",
    CONFIRM_LR_REGISTRATION: "/confirm-access/:lrFormId",
    VALIDATE_LR_MAIL: "/validate-mail/:token",
    SUBSCRIBER_REQUEST_FORM: "/subscribe/:uuid",
    SUBSCRIPTION_DETAIL: "/subscription/:uuid",
    SUBSCRIPTION_FEEDBACK: "/feedback/:uuid",
    PAYMENT: "/payment",
    PAYMENT_RETRY: "/payment-retry",
    PAYMENT_DUPLICATA: "/payment-duplicata/:transportTicketId",
    PAYMENT_RESULT_CB: "/payment/:billId",
    BILLING_HISTORY: "/billing-history",
    BILLING_DETAIL: "/billing/:id",
    TRAVELLER: "/traveller",
    LR_ACCOUNT: "/account",
    PUBLIC_DOCUMENTS: "/document/:documentType/*",
    LR2_WISH_VALIDATION: "/validate-lr2-wish/:token",
    LOGOUT: "/logout",
};

/**
 * @type {(string)[]}
 *
 * Produit la liste des routes en ordre alphabétique inversé (voir lien)
 *
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#react-router-v4v5
 */
export const ROUTES_ARRAY = Object
    .values(Routes)
    .sort((a, b) => a.localeCompare(b))
    .reverse();

export const getSubscriberRequestFormRoute = (subscriberUUID) => {
    return generatePath(Routes.SUBSCRIBER_REQUEST_FORM, {
        uuid: subscriberUUID,
    });
};

export const getBillingDetailRoute = (billingId) => {
    return generatePath(Routes.BILLING_DETAIL, {
        id: billingId,
    });
};

export const getSubscriptionDetailRoute = (uuid) => {
    return generatePath(Routes.SUBSCRIPTION_DETAIL, {
        uuid: uuid,
    });
};

export const getSubscriptionFeedBack = (uuid) => {
    return generatePath(Routes.SUBSCRIPTION_FEEDBACK, {
        uuid: uuid,
    });
};

export const getConfirmLrRegistration = (lrFormId) => {
    return generatePath(Routes.CONFIRM_LR_REGISTRATION, {
        lrFormId: lrFormId,
    });
};

export const getPaymentDuplicataRoute = (transportTicketId) => {
    return generatePath(Routes.PAYMENT_DUPLICATA, {
        transportTicketId: transportTicketId,
    });
};

export default Routes;
