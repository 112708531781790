import React, {createContext, useContext, useMemo, useReducer} from "react";
import {Backdrop, Box, CircularProgress} from "@mui/material";
import ActionTypes from "@/utils/ActionTypes";


export interface LoaderContextInterface {
    showLoader: () => void,
    hideLoader: () => void,
}

/**
 * Ce context permet de compter le nombre requêtes bloquantes en cours.
 * Il affiche un overlay flouté tant que toutes les requêtes ne sont pas finie.
 * Ce contexte possède un hook pour mettre à jour le nombre de requêtes en cours.
 */
const LoaderContext = createContext<LoaderContextInterface | null>(null);

function init(initialCount) {
    return {count: initialCount};
}

function requestCountReducer(state, action) {
    switch (action.type) {
        case ActionTypes.INCREMENT: {
            return {count: state.count + 1};
        }
        case ActionTypes.DECREMENT: {
            return {count: Math.max(state.count - 1, 0)};
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

/**
 * Hook permettant de gérer le nombre de requêtes bloquantes.
 * Attention, un hook ne peut pas être utilisé directement dans un composant issue d'une classe.
 * Pour l'utiliser au sein d'un composant de classe, il faut le passer via les props de celui ci (cf. StepGeneric.js)
 */
function useLoader(): LoaderContextInterface {
    const context = useContext(LoaderContext);
    if (context === null) {
        throw new Error("useLoader must be used within a LoaderProvider");
    }
    return context;
}

function LoaderProvider({children}) {
    const initialCount = 0;
    const [state, updateLoaderCounter] = useReducer(requestCountReducer, initialCount, init);

    const value = useMemo(() => ({
        showLoader: () => updateLoaderCounter({type: ActionTypes.INCREMENT}),
        hideLoader: () => updateLoaderCounter({type: ActionTypes.DECREMENT}),
    }), []);

    return (
        <LoaderContext.Provider value={value}>
            {children}
            <Backdrop open={state.count > 0} style={{zIndex: 9999}}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyItems: "center",
                    color: "white",
                }}>
                    <CircularProgress color="inherit"/>
                    <Box component="h3" color="white" pt={1}>Veuillez patienter...</Box>
                </Box>
            </Backdrop>
        </LoaderContext.Provider>
    );
}

export {LoaderProvider, useLoader, LoaderContext};