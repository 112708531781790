import mimeType from "mime-types";

/**
 * Format bytes as human-readable text.
 *
 * @param   {number}  bytes Number of bytes.
 * @param   {boolean} si    True to use metric (SI) units, aka powers of 1000. False to use
 *                          binary (IEC), aka powers of 1024.
 * @param   {number}  dp    Number of decimal places to display.
 * @returns {string}        Formatted string.
 */
export function humanFileSize(bytes: number, si: boolean = true, dp: number = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + " o";
    }

    const units = si
        ? ["ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + " " + units[u];
}

export function getMimeTypeFromDataURL(dataURI: string): string {
    return dataURI.split(",")[0].split(":")[1].split(";")[0];
}

export function dataURItoBlob(dataURI: string): Blob {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(",")[1]);

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //New Code
    return new Blob([ab], {type: getMimeTypeFromDataURL(dataURI)});
}

export function dataURItoFile(dataURI: string, fileName: string = "file"): File {
    const blob = dataURItoBlob(dataURI);
    const extension = mimeType.extension(blob.type);

    let fullFilename = fileName;
    if (extension) {
        fullFilename += "." + extension;
    }

    return new File([blob], fullFilename, {type: blob.type});
}

export function isDataUri(dataUri: string): boolean {
    return dataUri.startsWith("data:");
}

export function isFileImage(file: File): boolean {
    return file.type.startsWith("image/");
}

interface resizeImageOptions {
    /**
     * @default 600
     */
    maxWidthOrHeight?: number,
    /**
     * @default "jpg"
     * @see HTMLCanvasElement.toDataURL
     */
    type?: "png" | "jpeg",
    /**
     * @see HTMLCanvasElement.toDataURL
     */
    quality?: number,
}

/**
 * Redimensionne une image
 * @param  {File | string}          fileOrImageDataUrl
 * @param  {resizeImageOptions}     options
 * @return {Promise<File | string>}
 */
export async function resizeImage<T extends File | string>(fileOrImageDataUrl: T, options?: resizeImageOptions): Promise<T> {

    if (fileOrImageDataUrl instanceof File) {
        // Ensure it's an image
        if (!fileOrImageDataUrl.type.match(/image.*/)) {
            throw new Error("Le fichier n'est pas une image");
        }

        // Load the image
        const dataURL = await readFileDataURLAsync(fileOrImageDataUrl);
        const resizedDataURL = await resizeImageDateURL(dataURL, options);

        // @ts-ignore
        return dataURItoFile(resizedDataURL);
    } else {
        // @ts-ignore
        return await resizeImageDateURL(fileOrImageDataUrl, options);
    }
}

async function resizeImageDateURL(imageDataURL: string, {
    maxWidthOrHeight = 600,
    type = "jpeg",
    quality = undefined,
}: resizeImageOptions = {}): Promise<string> {
    // Ensure it's an image
    if (!isDataUri(imageDataURL)) {
        throw new Error("Ce n'est pas une image en DataURL");
    }

    // Load the image
    const image = await getImageElementFromDataURL(imageDataURL);
    // Resize the image
    let canvas = document.createElement("canvas"),
        max_size = maxWidthOrHeight,
        width = image.width,
        height = image.height;
    if (width > height) {
        if (width > max_size) {
            height *= max_size / width;
            width = max_size;
        }
    } else {
        if (height > max_size) {
            width *= max_size / height;
            height = max_size;
        }
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d")?.drawImage(image, 0, 0, width, height);

    return canvas.toDataURL("image/" + type, quality);
}

export function readFileDataURLAsync(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result as string);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
    });
}

export function getImageElementFromDataURL(dataURL: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = () => {
            resolve(image);
        };

        image.onerror = reject;

        image.src = dataURL;
    });
}


/* **********************************
 * 			    DEBUG
 * **********************************/

export function fileToJson(file: File) {
    return {
        name: file.name,
        size: humanFileSize(file.size, true, 3),
        type: file.type,
        lastModified: file.lastModified,
        webkitRelativePath: file.webkitRelativePath,
    };
}

export async function fileImageToJson(file: File) {
    const json = {
        ...fileToJson(file),
        img: {},
    };

    if (isFileImage(file)) {
        const dataURL = await readFileDataURLAsync(file);
        const img = await getImageElementFromDataURL(dataURL);
        json.img = {
            width: img.width,
            height: img.height,
            naturalWidth: img.naturalWidth,
            naturalHeight: img.naturalHeight,
        };
    }

    return json;
}