import {Link} from "react-router-dom";

function NotFoundPage() {
    return (
        <div>
            <h2>Page non trouvée</h2>
            <Link to="/">Retour à l'accueil</Link>
        </div>
    );
}

export default NotFoundPage;