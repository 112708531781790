import React, {VoidFunctionComponent} from "react";
import {AlertColor, Box} from "@mui/material";
import {BoxProps} from "@mui/material/Box/Box";

export type StatusBoxColor = AlertColor;

export type StatusBoxProps = BoxProps & {
    label: string,
    color?: StatusBoxColor,
}

const StatusBox: VoidFunctionComponent<StatusBoxProps> = ({
    label,
    color = "info",
    ...props
}) => {
    return (
        <Box
            {...props}
            component="span"
            sx={{
                typography: "body2",
                fontWeight: "medium",
                backgroundColor: color + ".light",
                color: color + ".main",
                padding: "6px 8px",
                borderRadius: 1,
            }}
        >
            {label}
        </Box>
    );
};

export default StatusBox;
