import React, {createContext, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useLoader} from "@/components/common/ui/LoaderContext";
import LegalRepresentativeService from "@/service/LegalRepresentativeService";
import Routes from "@/Routes";
import {useHistory} from "react-router-dom";
import {AnyObject} from "@/types/main";

export type LegalRepresentative = AnyObject & {
    getFullName: () => string,
    /**
     * Recharge le subscriber
     */
    reload: () => Promise<void>,
}

/**
 * Ce context permet de récupérer les information du représentant légal connecté
 */
const LegalRepresentativeContext = createContext<LegalRepresentative>({
    getFullName: () => "Anonymous",
    reload: async () => {
    },
});

function useLegalRepresentative() {
    const context = useContext(LegalRepresentativeContext);
    if (context === undefined) {
        throw new Error("useLegalRepresentative must be used within a LegalRepresentativeProvider");
    }
    return context;
}

const LegalRepresentativeProvider: FunctionComponent = ({children}) => {

    const loader = useLoader();
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);
    const [legalRepresentative, setLegalRepresentative] = useState<AnyObject>({});

    const reload = useCallback(async () => {
        loader.showLoader();
        try {
            const {data: lr} = await LegalRepresentativeService.getInfo();
            setLegalRepresentative(lr);
            setLoaded(true);

            if (!lr.filledInformationInForm) {
                history.push(Routes.REGISTER_LR);
            }
        } catch (e) {
            console.warn("Une erreur est survenue lors du chargement de l'utilisateur");
        } finally {
            loader.hideLoader();
        }
    }, [loader, history]);

    useEffect(() => {
        reload();
    }, [reload]);

    const value: LegalRepresentative = useMemo(() => ({
        ...legalRepresentative,
        getFullName: () => legalRepresentative.firstName + " " + legalRepresentative.lastName,
        reload,
    }), [legalRepresentative, reload]);

    if (loaded) {
        return (
            <LegalRepresentativeContext.Provider value={value}>
                {children}
            </LegalRepresentativeContext.Provider>
        );
    } else {
        return null;
    }

};

export {LegalRepresentativeProvider, useLegalRepresentative, LegalRepresentativeContext};