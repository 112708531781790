/**
 * @enum {string}
 */
const PaymentTypeEnum = {
    BANK_DIRECT_DEBIT: "BANK_DIRECT_DEBIT",
    CB_WEB: "CB_WEB",
    CB_AGENCY: "CB_AGENCY",
    BANK_TRANSFERT: "BANK_TRANSFERT",
    CHECK: "CHECK",
    CASH: "CASH",
    SOCIAL: "SOCIAL",
    DIRECT_AO2: "DIRECT_AO2",
    TOTAL_BY_FUNDING_ORGANIZATION: "TOTAL_BY_FUNDING_ORGANIZATION",
    FUNDING_ORGANIZATION: "FUNDING_ORGANIZATION",
    INCOME_TITLE: "INCOME_TITLE",
    OTHER: "OTHER",
    NULL: "null",
};

const TreasuryType = {
    TREASURY: "TREASURY",
    TRESOR_PUBLIC_ORMC: "TRESOR_PUBLIC_ORMC",
    TRESOR_PUBLIC_PAY_FIP: "TRESOR_PUBLIC_PAY_FIP",
    PRIVATE_EXTERNAL: "PRIVATE_EXTERNAL"
};

const BillStatusEnum = {
    ARCHIVED: "ARCHIVED",
    TO_CASH: "TO_CASH",
    TO_CASH_SCHEDULED: "TO_CASH_SCHEDULED",
    CANCELED: "CANCELED",
};

const PaymentAttempsStatusEnum = {
    SENDED: "SENDED",
    TELETRANSMISSION: "TELETRANSMISSION",
    ONLINE_PROCEDURE_SENT: "ONLINE_PROCEDURE_SENT",
    ARCHIVED: "ARCHIVED",
    AUTHORIZED_DELAYED: "AUTHORIZED_DELAYED",
    REJECTED: "REJECTED",
    ECHEC: "ECHEC",
    TIMED_OUT: "TIMED_OUT",
};

/**
 * @enum {string}
 */
const PaymentPeriodicEnum = {
    UNIQUE: "UNIQUE",
    UNIQUE_DELAYED: "UNIQUE_DELAYED",
    QUARTERLY: "QUARTERLY",
    HALFYEARLY: "HALFYEARLY",
    MONTHLY: "MONTHLY",
};

const PaymentPeriodic = {
    [PaymentPeriodicEnum.UNIQUE]: {label: "Annuel immédiat", value: PaymentPeriodicEnum.UNIQUE, nbDueDates: 1},
    [PaymentPeriodicEnum.UNIQUE_DELAYED]: {
        label: "Annuel différé",
        value: PaymentPeriodicEnum.UNIQUE_DELAYED,
        nbDueDates: 1
    },
    [PaymentPeriodicEnum.QUARTERLY]: {label: "Trimestriel", value: PaymentPeriodicEnum.QUARTERLY, nbDueDates: 3},
    [PaymentPeriodicEnum.MONTHLY]: {label: "Mensuel", value: PaymentPeriodicEnum.MONTHLY, nbDueDates: 1},
    [PaymentPeriodicEnum.HALFYEARLY]: {label: "Semestriel", value: PaymentPeriodicEnum.HALFYEARLY, nbDueDates: 2},
};

const PaymentOrderStatusEnum = {
    IN_PROGRESS: "IN_PROGRESS",
    PAID: "PAID",
    CLOSED: "CLOSED",
    CANCELED: "CANCELED",
    DELETED: "DELETED",
};

const PaymentPlatformNameOfTreasury = {
    [TreasuryType.PRIVATE_EXTERNAL]: "PayZen",
    [TreasuryType.TRESOR_PUBLIC_PAY_FIP]: "PayFip",
};

export {
    PaymentTypeEnum,
    TreasuryType,
    BillStatusEnum,
    PaymentAttempsStatusEnum,
    PaymentPeriodicEnum,
    PaymentPeriodic,
    PaymentOrderStatusEnum,
    PaymentPlatformNameOfTreasury
};