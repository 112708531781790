import React from "react";
import Icons from "@/components/common/styles/Icons";
import {Button, Grid} from "@mui/material";
import {useSettingsContext} from "@/components/common/settings/SettingsContext";
import {useHistory} from "react-router-dom";
import Routes from "@/Routes";
import "./LinkDeclareSubscribers.scoped.scss";
import {useDisplay} from "@/components/common/ui/DisplayContext";
import Icon from "@/components/common/ui/Icon";
import Images from "@/components/common/styles/Images"

function LinkDeclareSubscribers() {
    const {isMobile, isDesktop} = useDisplay();
    const {
        SCHOLAR_YEAR: scholarYear,
        SUBSCRIPTIONS_OPEN: subscriptionsOpen,
    } = useSettingsContext();

    const history = useHistory();
    const registerChildren = () => {
        history.push(Routes.REGISTER_CHILDREN);

    };

    return (
        <>
            <Grid container direction={isMobile ? "column" : "row-reverse"} alignItems={"center"} justifyItems={"center"}>
                <Grid item xs={12} md={6}>
                    <img src={Images.bus} alt={"Bus logo"} style={{height: 160, maxWidth: "100%"}}/>
                </Grid>
                {subscriptionsOpen &&
                <Grid item xs={12} md={6}>
                    <div className="desktop-rounded-box" style={{maxWidth: "500px"}}>
                        <Grid container spacing={2} sx={{p: 2}} style={{textAlign: "center"}}>
                            {isDesktop && <Grid item xs={12}>
                                <Icon className="useradd-img" src={Icons.addChild}/>
                            </Grid>}
                            <Grid item xs={12} className="info-block">
                                Ajouter l'ensemble de vos enfants à inscrire pour la rentrée {scholarYear}
                            </Grid>
                            <Grid item xs={12}>
                                <Button component="a" onClick={registerChildren}>Ajouter mes enfants</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                }
            </Grid>
        </>
    );
}

export default LinkDeclareSubscribers;