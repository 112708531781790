// Axios Configuration
import axios from "axios";
import store from "@/utils/Store";
import KeyCloakService from "@/service/KeyCloakService";
import Qs from "qs";
import urlJoin from "url-join";

const HTTP_CODE = {
    OK: 200,
    NOT_MODIFIED: 304,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    ACCESS_DENIED: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    PRECONDITION_FAILED: 412, // Client schema not found
    INTERNAL_SERVER_ERROR: 500,
    GATEWAY_TIMEOUT: 504,
};

const BUSINESS_EXCEPTION = {
    ACTION_IMPOSSIBLE: 6,
};

const httpService = axios.create({
    baseURL: window._env_.REACT_APP_BASE_API_URL,
    paramsSerializer: function (params) {
        return Qs.stringify(params, {arrayFormat: "repeat", skipNulls: true});
    },
});

httpService.interceptors.request.use(
    config => {
        // Si le header est fournis on conserve la valeur
        if (store.commonCode && config.headers["x-okina-client-code"] == null) {
            config.headers["x-okina-client-code"] = store.commonCode;
        }
        if (store.authToken && !config.public) {
            config.headers["Authorization"] = "Bearer " + store.authToken;
        }

        return config;
    },
    err => {
        console.log("HttpService Failed", err);
        return Promise.reject(err);
    }
);

httpService.interceptors.response.use(
    response => response,
    async error => {
        if (error.response) {
            error = error.response;
        }
        if (!error.status) {
            // Network error
            console.error("Server unreachable", error);
        } else {
            if (error.status === HTTP_CODE.UNAUTHORIZED) {
                KeyCloakService.login();
            } else if (error.status === HTTP_CODE.PRECONDITION_FAILED) {
                let notFoundUrl = urlJoin(window.location.href.split("#")[0], "#", (window._env_.REACT_APP_DEFAULT_COMMON_CODE ? "" : store.commonCode), "404");
                window.location.href = notFoundUrl;
            }
        }

        return Promise.reject(error);
    }
);

export default httpService;
export {HTTP_CODE, BUSINESS_EXCEPTION};