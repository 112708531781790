import AbstractService, {CustomAxiosRequestConfig} from "@/service/AbstractService";
import {PaymentTypeEnum} from "frontend.common/src/PaymentConstants";
import {AnyObject} from "@/types/main";
import {CreditNoteStatus} from "@/models/CreditNote";

const PaymentTypeConfig = {
    [PaymentTypeEnum.BANK_DIRECT_DEBIT]: {label: "Prélèvement"},
    [PaymentTypeEnum.CB_WEB]: {label: "CB par internet"},
    [PaymentTypeEnum.CB_AGENCY]: {label: "CB en agence"},
    [PaymentTypeEnum.BANK_TRANSFERT]: {label: "Virement"},
    [PaymentTypeEnum.CHECK]: {label: "Chèque"},
    [PaymentTypeEnum.CASH]: {label: "Espèce"},
    [PaymentTypeEnum.SOCIAL]: {label: "Social"},
    [PaymentTypeEnum.OTHER]: {label: "Autre"},
};

class PaymentService extends AbstractService {

    constructor() {
        super("/public/fiti/subscription/dynamic/payment");
    }

    /* **********************************
     * 			    Payment
     * **********************************/

    /**
     * Génère les ordres de paiements pour les dossiers sélectionnés
     * Retourne les informations de redirection vers Payzen
     */
    createCbPayment(periodic: string, subscriptions: number[]) {
        return this.post(["cb"], subscriptions, {
            params: {
                periodic: periodic,
            },
        });
    }

    createOfflinePayment(periodic: string, subscriptions: number[]) {
        return this.post<void>("offline", subscriptions, {
            params: {
                periodic: periodic,
            },
        });
    }

    getPaymentForm(billPaymentId: number): Promise<AnyObject> {
        return this.get(["bill", billPaymentId, "payment-form"]);
    }

    getImmediatePaymentParameters(billId) {
        return this.get(["bill", billId, "immediate-payment-parameters"]);
    }

    getPaymentRetryMode(billPaymentId) {
        return this.get(["bill", billPaymentId, "payment-retry-mode"]);
    }

    /* **********************************
     * 			   Bill payments
     * **********************************/

    getBillsWithPreviousYears() {
        return this.get<AnyObject[]>("bills-with-previous-years");
    }

    getBills() {
        return this.get<AnyObject[]>("bills");
    }

    getBill(id) {
        return this.get(["bill", id]);
    }

    getBillWithSchemaName(id, schemaName) {
        return this.get(["bill", id], {
            headers: {"x-okina-referential": schemaName},
        });
    }

    getBillPdf(id): Promise<Blob> {
        return this.getFile(["bill", id, "pdf"], {type: "application/pdf"});
    }

    getBillPdfWithSchemaName(id, schemaName): Promise<Blob> {
        return this.getFile(
            ["bill", id, "pdf"],
            {type: "application/pdf"},
            {
                headers: {"x-okina-referential": schemaName},
            }
        );
    }

    getBillPdfBySubscriber(subscriberId): Promise<Blob> {
        return this.getFile(["bill", "by-subscriber", subscriberId], {type: "application/pdf"});
    }

    /* **********************************
     * 			   Credit note
     * **********************************/

    getCreditNotePdf(id: number, schemaName?: string): Promise<File> {
        const config: CustomAxiosRequestConfig = {};

        if (schemaName?.length) {
            config.headers = {"x-okina-referential": schemaName};
        }

        return this.getFile(["credit-note", id, "pdf"], {type: "application/pdf"}, config);
    }

    /* **********************************
     * 			    Others
     * **********************************/

    getTreasuryById(treasuryId) {
        return this.get(["treasury", treasuryId]);
    }

    hasTreasuryOrmc() {
        return this.get<boolean>("/treasury/has-ormc");
    }

    getDuplicataFareAmount() {
        return this.get(["duplicata-fare"]);
    }

    /* **********************************
     * 			    Utils
     * **********************************/

    getPaymentTypeLabel(paymentType: string): string {
        return PaymentTypeConfig[paymentType]?.label || "Non défini";
    }

    getCreditNoteStatusLabel(status: CreditNoteStatus): string {
        switch (status) {
            case CreditNoteStatus.ARCHIVED:
                return "Traité";
            case CreditNoteStatus.WAIT:
                return "En attente";
            default:
                return "Inconnu";
        }
    }
}

export default new PaymentService();