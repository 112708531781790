import backgroundHeader from "@/assets/img/default/background-header.png";
import bill from "@/assets/img/default/bill.svg";
import bus from "@/assets/img/default/bus.svg";
import cbKo from "@/assets/img/default/cb-ko.svg";
import cbOk from "@/assets/img/default/cb-ok.svg";
import duplicataCheckIn from "@/assets/img/default/duplicata-check-in.svg";
import emailSent from "@/assets/img/default/email-sent.svg";
import logo from "@/assets/img/default/logo.svg";
import offlinePayment from "@/assets/img/default/offline-payment.svg";
import okSuccessfulCheck from "@/assets/img/default/ok-successful-check.svg";
import subscriptionInProgress from "@/assets/img/default/subscription-in-progress.svg";
import subscriptionSuccessfull from "@/assets/img/default/subscription-successfull.svg";
import transportTicketPlaceholder from "@/assets/img/default/transport-ticket-placeholder.svg";
import helper1 from "@/assets/img/default/helper/helper-1.svg";
import helper2 from "@/assets/img/default/helper/helper-2.svg";
import helper3 from "@/assets/img/default/helper/helper-3.svg";

import {IImages} from "./Images";

let warning;

const Images: IImages = {
    backgroundHeader,
    bill,
    bus,
    cbKo,
    cbOk,
    duplicataCheckIn,
    emailSent,
    logo,
    offlinePayment,
    okSuccessfulCheck,
    subscriptionInProgress,
    subscriptionSuccessfull,
    transportTicketPlaceholder,
    warning,
    helper1,
    helper2,
    helper3,
}

export default Images;