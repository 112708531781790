import {AppBar, Backdrop, Box, Button, IconButton, List, ListItem, ListItemText, Toolbar} from "@mui/material";
import React from "react";
import Routes from "@/Routes";
import Icon from "@/components/common/ui/Icon";
import Icons from "@/components/common/styles/Icons";
import {Link, useHistory} from "react-router-dom";
import "./SideMenu.scoped.scss";
import {useLegalRepresentative} from "@/components/common/LegalRepresentativeContext";
import LegalMentions from "@/components/common/ui/LegalMentions";
import Logo from "@/components/common/ui/Logo";

function SideMenu({
    open = false,
    onClose = () => null,
}) {
    const history = useHistory();
    const legalRepresentative = useLegalRepresentative();

    return (
        <>
            <Backdrop
                className="menu-backdrop"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
            >
                <div className="menu-container">
                    <AppBar position="relative" className="app-bar">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" sx={{p: 0}} onClick={() => history.push(Routes.HOME_AUTH)}>
                                <Logo style={{maxHeight: "76px", width: "auto"}}/>
                            </IconButton>
                            <span className="app-bar-spacer"/>
                            <IconButton color="inherit" onClick={onClose}>
                                <Icon src={Icons.close} alt="Menu close"/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="menu-content-container">
                        {legalRepresentative && <div className="menu-content">
                            <Box p={2}>
                                <Icon src={Icons.user}/>
                            </Box>
                            <Box p={2} className="username">
                                {legalRepresentative.getFullName()}
                            </Box>
                            <Box p={2}>
                                <List>
                                    <ListItem button>
                                        <ListItemText
                                            primary={"Mes informations"}
                                            className="menu-item"
                                            onClick={() => history.push(Routes.LR_ACCOUNT)}
                                        />
                                        <Icon src={Icons.chevronRight}/>
                                    </ListItem>
                                    <ListItem button>
                                        <ListItemText
                                            primary={"Mes factures"}
                                            className="menu-item"
                                            onClick={() => history.push(Routes.BILLING_HISTORY)}
                                        />
                                        <Icon src={Icons.chevronRight}/>
                                    </ListItem>
                                </List>
                            </Box>
                        </div>}
                        <Button variant="text" fullWidth component={Link} to={{pathname: Routes.LOGOUT}}>
                            Me déconnecter
                        </Button>
                        <div className="menu-footer">
                            <LegalMentions/>
                        </div>
                    </div>
                </div>
            </Backdrop>
        </>
    );
}

export default SideMenu;