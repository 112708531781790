import {ListItemButton, ListItemText} from "@mui/material";
import LinkWithIcon from "@/components/common/ui/LinkWithIcon";
import {SubscriberRequest} from "@/service/SubscriberService";
import {getSubscriberRequestFormRoute} from "@/Routes";
import {displayDate} from "@/plugins/dateFns";
import {Link} from "react-router-dom";
import {useSettingsContext} from "@/components/common/settings/SettingsContext";

export type ListSubscribersUnregisteredItemProps = {
    subscriberRequest: SubscriberRequest
}

const ListSubscribersUnregisteredItem = ({
    subscriberRequest,
}: ListSubscribersUnregisteredItemProps) => {
    const subscriber = subscriberRequest.content.subscriber;
    const subscriberFullName = subscriber.firstName + " " + subscriber.lastName;
    const subscriberBirthDate = displayDate(subscriber.birthDate);
    const {SUBSCRIPTIONS_OPEN: subscriptionsOpen} = useSettingsContext();

    return (
        <ListItemButton key={subscriberRequest.uuid} component={Link} to={getSubscriberRequestFormRoute(subscriberRequest.uuid)} disabled={!subscriptionsOpen}>
            <ListItemText primary={subscriberFullName} secondary={subscriberBirthDate}/>
            <LinkWithIcon asSpan>Inscrire</LinkWithIcon>
        </ListItemButton>
    );
};

export default ListSubscribersUnregisteredItem;