import Icons from "@/components/common/styles/Icons";
import Icon from "@/components/common/ui/Icon";
import {Link, LinkProps} from "@mui/material";
import {Link as RouterLink, LinkProps as RouterLinkProps} from "react-router-dom";
import { useTheme } from '@mui/material/styles';

type LinkWithIconProps = Partial<RouterLinkProps> & LinkProps & { asSpan?: boolean };

const LinkWithIcon = ({to, asSpan, children, ...props}: LinkWithIconProps) => {
    const theme = useTheme();
    return (
        <Link
            {...props}
            to={to}
            style={{
                display: "flex",
                cursor: "pointer",
            }}
            component={to ? RouterLink : (asSpan ? "span" : "a")}
        >
            {children}
            &nbsp;<Icon src={Icons.chevronRightRed} color={theme.palette.primary.main} size="small"/>

        </Link>
    );
};

export default LinkWithIcon;