// eslint-disable-next-line no-unused-vars
import {AxiosInstance, AxiosPromise, AxiosRequestConfig} from "axios";
import httpService from "./HttpService";
import * as path from "path";
import contentDisposition from "content-disposition";

type ServiceUrl = string | number | Array<string | number>;

export type CustomAxiosRequestConfig = Omit<AxiosRequestConfig, "url"> & {
    url?: ServiceUrl,
    public?: boolean
}

export default class AbstractService {

    protected readonly _resourcePath: string;

    protected readonly http: AxiosInstance;

    constructor(resourcePath: string) {
        if (this.constructor === AbstractService) {
            throw new TypeError("Abstract class \"AbstractService\" cannot be instantiated directly");
        }

        this._resourcePath = resourcePath;
        this.http = httpService;
    }

    protected get resourcePath() {
        return this._resourcePath;
    }

    protected request<T = any>(config: CustomAxiosRequestConfig): AxiosPromise<T> {
        if (config.url) {
            if (Array.isArray(config.url)) {
                config.url = path.join(...config.url.map(String));
            } else {
                config.url = String(config.url);
            }

            config.url = path.join(this.resourcePath, config.url);
        } else {
            config.url = this.resourcePath;
        }

        return this.http.request(config as AxiosRequestConfig);
    }

    protected get<T = any>(url?: ServiceUrl, config: CustomAxiosRequestConfig = {}) {
        return this.request<T>({
            method: "get",
            url: url,
            ...config,
        });
    }

    protected getFile(url?: ServiceUrl, blobOptions?: BlobPropertyBag, config: CustomAxiosRequestConfig = {}): Promise<File> {
        return this.request<BlobPart>({
            method: "get",
            url: url,
            responseType: "blob",
            ...config,
        }).then((response) => {
            let fileName = "fichier";
            const disposition = response.headers["content-disposition"];
            if (disposition) {
                const parseDisposition = contentDisposition.parse(disposition);
                fileName = parseDisposition.parameters.filename;
            }

            return new File([response.data], fileName, blobOptions);
        });
    }

    protected delete<T = any>(url?: ServiceUrl, config: CustomAxiosRequestConfig = {}) {
        return this.request<T>({
            method: "delete",
            url: url,
            ...config,
        });
    }

    protected post<T = any>(url?: ServiceUrl, data?: any, config: CustomAxiosRequestConfig = {}) {
        return this.request<T>({
            method: "post",
            url: url,
            data: data,
            ...config,
        });
    }

    protected put<T = any>(url?: ServiceUrl, data?: any, config: CustomAxiosRequestConfig = {}) {
        return this.request<T>({
            method: "put",
            url: url,
            data: data,
            ...config,
        });
    }

    protected patch<T = any>(url?: ServiceUrl, data?: any, config: CustomAxiosRequestConfig = {}) {
        return this.request<T>({
            method: "patch",
            url: url,
            data: data,
            ...config,
        });
    }
}
