import {useEffect, useState, VoidFunctionComponent} from "react";
import Icons from "@/components/common/styles/Icons";
import SubscriberFilesService from "@/service/SubscriberFilesService";
import {Box} from "@mui/material";

export type UserPictureProps = {
    subscriberRequestUUID: string,
    isValidPhoto?: boolean,
};

const UserPicture: VoidFunctionComponent<UserPictureProps> = ({
    subscriberRequestUUID,
    isValidPhoto = false,
}) => {
    const [picture, setPicture] = useState<File>();

    useEffect(() => {
        if (isValidPhoto) {
            SubscriberFilesService.getSubscriberRequestPicture(subscriberRequestUUID)
                .then(pictContent => {
                    setPicture(pictContent);
                })
                .catch(err => console.error("Error while loading photo", err));
        }

    }, [isValidPhoto, subscriberRequestUUID]);

    return (
        <Box component={"img"}
            src={picture ? URL.createObjectURL(picture) : Icons.user}
            sx={{
                height: "72px",
                width: "58px",
                borderRadius: 1,
                objectFit: "contain",
                mr: 1,
                backgroundColor: "white",

                border: (theme) => {
                    // @ts-ignore
                    return picture ? "none" : "2px solid " + theme.colors.darkBorder
                },
            }}
        />);
}

export default UserPicture;