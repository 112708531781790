import {AppBar, Box, Button, IconButton, Toolbar} from "@mui/material";
import Icons from "@/components/common/styles/Icons";
import Icon from "@/components/common/ui/Icon";
import React from "react";
import {Link, useHistory} from "react-router-dom";
import Routes from "@/Routes";
import SideMenu from "@/components/common/ui/SideMenu";
import {useDisplay} from "@/components/common/ui/DisplayContext";
import "./HeaderMenu.scoped.scss";
import Popin from "@/components/common/ui/Popin/Popin";
import LegalMentions from "@/components/common/ui/LegalMentions";
import useStateOpenClose from "@/hooks/useStateOpenClose";
import Logo from "@/components/common/ui/Logo";

function HeaderMenu({
    noBurger = false,
    title = "",
}) {
    const {isDesktop} = useDisplay();

    const history = useHistory();
    const [isOpen, setIsOpen] = React.useState(false);

    const [isInfoPopinVisible, showInfoPopin, hideInfoPopin] = useStateOpenClose(false);

    return (
        <>
            <AppBar position="relative">
                <Toolbar className={isDesktop ? "desktop-menu" : "burger-menu"}>
                    <IconButton edge="start" color="inherit" sx={{ p: 0 }} onClick={() => history.push(Routes.HOME_AUTH)}>
                        <Logo style={{maxHeight: "76px", width: "auto"}}/>
                    </IconButton>
                    <span style={{
                        flexGrow: 1,
                        textAlign: "center",
                    }}><h1>{title}</h1></span>
                    {!isDesktop && !noBurger &&
                        <IconButton color="inherit" onClick={() => setIsOpen(true)}>
                            <Icon src={Icons.burgerMenu} alt="Menu"/>
                        </IconButton>
                    }
                    {isDesktop && !noBurger &&
                        <div className="block-menu">
                            <Link to={{pathname: Routes.HOME_AUTH}}>
                                <Icon src={Icons.homeMap}/>
                                <div>Accueil</div>
                            </Link>
                            <Link to={{pathname: Routes.LR_ACCOUNT}}>
                                <Icon src={Icons.user}/>
                                <div>Mon compte</div>
                            </Link>
                            <Link to={{pathname: Routes.BILLING_HISTORY}}>
                                <Icon src={Icons.creditCard}/>
                                <div>Factures</div>
                            </Link>
                            <Link to={"#"} onClick={showInfoPopin}>
                                <Icon src={Icons.questionMark}/>
                                <div>Informations</div>
                            </Link>
                            <Link to={{pathname: Routes.LOGOUT}}>
                                <Icon src={Icons.logoutIcon}/>
                                <div>Déconnexion</div>
                            </Link>
                            <Popin
                                isOpen={isInfoPopinVisible}
                                onClose={hideInfoPopin}
                                title={"Informations"}
                            >
                                <LegalMentions/>
                                <Box pt={2}>
                                    <Button fullWidth onClick={hideInfoPopin}>Fermer </Button>
                                </Box>
                            </Popin>
                        </div>
                    }
                </Toolbar>
            </AppBar>
            <SideMenu
                open={isOpen && !isDesktop}
                onClose={() => setIsOpen(false)}
            />
        </>
    );
}

export default HeaderMenu;