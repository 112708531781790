import {createContext, useContext} from "react";


export interface DisplayContextInterface {
    isDesktop: boolean,
    isMobile: boolean,
}


const DisplayContext = createContext<DisplayContextInterface>({
    isDesktop: false,
    isMobile: true,
});

/**
 * Hook permettant de déterminer si on est en mode mobile ou desktop
 */
function useDisplay(): DisplayContextInterface {
    const context = useContext(DisplayContext);
    if (context === undefined) {
        throw new Error("useDisplay must be used within a DisplayProvider");
    }
    return context;
}

export {useDisplay, DisplayContext};