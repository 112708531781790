import backgroundHeader from "@/assets/img/RNA/background-header.png";
import bill from "@/assets/img/RNA/bill.svg";
import bus from "@/assets/img/RNA/bus.png";
import cbKo from "@/assets/img/RNA/cb_ko.svg";
import cbOk from "@/assets/img/RNA/cb_ok.svg";
import duplicataCheckIn from "@/assets/img/RNA/duplicata_check_in.svg";
import emailSent from "@/assets/img/RNA/email-sent.svg";
import logo from "@/assets/img/RNA/logo.svg";
import offlinePayment from "@/assets/img/RNA/offline-payment.svg";
import okSuccessfulCheck from "@/assets/img/RNA/ok_successful_check.svg";
import subscriptionInProgress from "@/assets/img/RNA/subscriptionInProgress.svg";
import subscriptionSuccessfull from "@/assets/img/RNA/subscriptionSuccessfull.svg";
import transportTicketPlaceholder from "@/assets/img/RNA/transport_ticket_placeholder.svg";
import warning from "@/assets/img/RNA/warning.svg";
import helper1 from "@/assets/img/RNA/helper/helper-1.svg";
import helper2 from "@/assets/img/RNA/helper/helper-2.svg";
import helper3 from "@/assets/img/RNA/helper/helper-3.svg";

import {IImages} from "./Images";

const Images: IImages = {
    backgroundHeader,
    bill,
    bus,
    cbKo,
    cbOk,
    duplicataCheckIn,
    emailSent,
    logo,
    offlinePayment,
    okSuccessfulCheck,
    subscriptionInProgress,
    subscriptionSuccessfull,
    transportTicketPlaceholder,
    warning,
    helper1,
    helper2,
    helper3,
}

export default Images;