import IconsRNA from "./IconsRNA";
import IconsDefault from "./IconsDefault";

export interface IIcons {
    warning: any;
    close: any;
    chevronLeft: any;
    chevronDown: any;
    chevronUp: any;
    homeFlat: any;
    logoSmall: any;
    files: any;
    success: any;
    failure: any;
    downloadFile: any;
    childAvatar: any;
    pencilEdit: any;
    bus: any;
    addChild: any;
    chevronRightRed: any;
    chevronRight: any;
    logo: any;
    user: any;
    fileUser: any;
    options: any;
    folder: any;
    chatMessage: any;
    archive: any;
    burgerMenu: any;
    markerSelected: any;
    markerUnselected: any;
    home: any;
    building: any;
    homeMap: any;
    position: any;
    placeholder: any;
    creditCard: any;
    paper: any;
    circle: any;
    incomplete: any;
    markerOutlined: any;
    logoutIcon: any;
    questionMark: any;
    timer: any;
}

const Icons: IIcons = window._env_.REACT_APP_PROJECT === "RNA" ? IconsRNA : IconsDefault;

export default Icons;