import Keycloak from "keycloak-js";
import store from "@/utils/Store";
import Routes, {getConfirmLrRegistration} from "@/Routes";

const INTERVAL_CHECK_REFRESH_TOKEN = 60;

class KeyCloakService {

    keycloak;

    _loadKeycloak() {
        let computedRealm;

        if (window._env_.REACT_APP_DEFAULT_COMMON_CODE) {
            computedRealm = window._env_.REACT_APP_AUTH_REALM;
        } else {
            const commonCode = store.commonCode;
            if (commonCode) {
                computedRealm = `${window._env_.REACT_APP_AUTH_REALM}-${commonCode}`;
            } else {
                throw new Error("Impossible de déterminer le realm Keycloak");
            }
        }

        this.keycloak = new Keycloak({
            url: window._env_.REACT_APP_AUTH_URL,
            realm: computedRealm,
            clientId: window._env_.REACT_APP_AUTH_CLIENT_ID,
        });

    }

    async init() {
        this._loadKeycloak();

        await this.keycloak.init({
            onLoad: "check-sso",
            checkLoginIframe: false,
        });
        await store.loadKeycloak(this.keycloak);
        if (this.isAuthenticated()) {
            try {
                //Token Refresh
                if (this._intervalRefreshTokenId) {
                    clearInterval(this._intervalRefreshTokenId);
                }

                this._intervalRefreshTokenId = setInterval(() => {
                    this.keycloak.updateToken(INTERVAL_CHECK_REFRESH_TOKEN - 5).then((refreshed) => {
                        if (refreshed) {
                            console.info("Token refreshed: " + refreshed);
                        } else {
                            console.debug("Token not refreshed, valid for "
                                + Math.round(this.keycloak.tokenParsed.exp + this.keycloak.timeSkew - (new Date().getTime() / 1000)) + " seconds");
                        }
                    }).catch(() => {
                        console.error("Failed to refresh token");
                    });
                }, INTERVAL_CHECK_REFRESH_TOKEN * 1000);
            } catch (e) {
                console.error("Authentication failed", e);
            }
        }
    }

    isAuthenticated() {
        return this.keycloak && this.keycloak.authenticated;
    }

    _intervalRefreshTokenId;

    getLoginUrl() {
        return this.keycloak.createLoginUrl();
    }

    getLogoutUrl() {
        return this.keycloak.createLogoutUrl({
            redirectUri: store.baseUrl + Routes.HOME_PUBLIC,
        });
    }

    login(options) {
        window.location = this.getLoginUrl(options);
    }

    logout() {
        window.location = this.getLogoutUrl();
    }

    loginWithRedirectToHomePage() {
        window.location = this.keycloak.createLoginUrl({
            redirectUri: store.baseUrl + Routes.HOME_AUTH,
        });
    }

    loginWithRedirectToConfirmAccess(lrFormId, email) {
        window.location = this.keycloak.createLoginUrl({
            redirectUri: store.baseUrl + getConfirmLrRegistration(lrFormId),
            loginHint: email,
            action: "register",
        });
    }
}


export default new KeyCloakService();