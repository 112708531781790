import React, {lazy, Suspense} from "react";
import {Switch} from "react-router-dom";

// Main components
import NotFoundPage from "@/components/NotFoundPage";
import ManageSubscribers from "@/components/subscriber-list/ManageSubscribers";
import PublicHomeRNA from "@/components/PublicHomeRNA";
import PublicHomeDefault from "@/components/PublicHomeDefault";

import PrivateRoute from "@/PrivateRoute";
import UtilityFunctions from "@/utils/UtilityFunctions";

// Sentry
import SentryRoute from "@/SentryRoute";
import KeyCloakService from "@/service/KeyCloakService";
import Routes from "@/Routes";
import SettingsContextProvider from "@/components/common/settings/SettingsContextProvider";

const CreateLRAccess = lazy(() => import(/* webpackChunkName: "keycloakConfirm" */ "@/components/lr-registration/CreateLRAccess"));
const ConfirmLRRegistration = lazy(() => import(/* webpackChunkName: "keycloakConfirm" */ "@/components/lr-registration/ConfirmRegistration"));

const RegisterLr = lazy(() => import(/* webpackChunkName: "RegisterLR" */ "@/components/lr-registration/RegisterLr"));
const ValidateLrMail = lazy(() => import(/* webpackChunkName: "RegisterLR" */ "@/components/lr-registration/ValidateLrMail"));
const RegisterSubscribersPage = lazy(() => import(/* webpackChunkName: "SubscriberForm" */ "@/components/subscriber-registration/RegisterSubscribersPage"));
const StepGeneric = lazy(() => import(/* webpackChunkName: "SubscriberForm" */ "@/components/form-subscription/step/StepGeneric"));
const SubscriptionFeedBack = lazy(() => import(/* webpackChunkName: "SubscriberForm" */ "@/components/form-subscription/SubscriptionFeedBack"));
const SubscriptionDetail = lazy(() => import(/* webpackChunkName: "SubscriptionDetail" */ "@/components/form-subscription/SubscriptionDetail"));

const Payment = lazy(() => import(/* webpackChunkName: "Payment" */ "@/components/payment/Payment"));
const PaymentResultCb = lazy(() => import(/* webpackChunkName: "Payment" */ "@/components/payment/PaymentResultCb"));
const PaymentRetry = lazy(() => import(/* webpackChunkName: "Payment" */ "@/components/payment/PaymentRetry"));
const PaymentDuplicata = lazy(() => import(/* webpackChunkName: "Payment" */ "@/components/payment/PaymentDuplicata"));
const BillingHistory = lazy(() => import(/* webpackChunkName: "Billing" */ "@/components/billing/BillingHistory"));
const BillingDetail = lazy(() => import(/* webpackChunkName: "Billing" */ "@/components/billing/BillingDetail"));

const FormTravellerInfo = lazy(() => import(/* webpackChunkName: "Traveller" */ "@/components/traveller-information/FormTravellerInfo"));
const EditLr = lazy(() => import(/* webpackChunkName: "Account" */ "@/components/lr-registration/EditLrPage"));

const PublicDocuments = lazy(() => import(/* webpackChunkName: "PublicDocuments" */ "@/components/public-documents/PublicDocuments"));

const ValidateLR2Wish = lazy(() => import(/* webpackChunkName: "PublicDocuments" */ "@/components/lr-registration/ValidateLR2Wish"));

const DebugPage = lazy(() => import(/* webpackChunkName: "debug" */ "@/components/common/debug/DebugPage"));

export function RoutesComponent() {
    return (
        <SettingsContextProvider>
            <Suspense fallback={<div>Chargement...</div>}>
                <Switch>
                    {window._env_.REACT_APP_PROJECT === "RNA" ?
                        <SentryRoute exact path={Routes.HOME_PUBLIC} component={PublicHomeRNA}/> :
                        <SentryRoute exact path={Routes.HOME_PUBLIC} component={PublicHomeDefault}/>
                    }

                    <PrivateRoute path={Routes.HOME_AUTH} component={ManageSubscribers}/>

                    <PrivateRoute path={Routes.REGISTER_LR} component={RegisterLr}/>
                    <PrivateRoute path={Routes.VALIDATE_LR_MAIL} component={ValidateLrMail}/>
                    <PrivateRoute path={Routes.REGISTER_CHILDREN} component={RegisterSubscribersPage}/>
                    <PrivateRoute path={Routes.SUBSCRIBER_REQUEST_FORM} component={StepGeneric}/>
                    <PrivateRoute path={Routes.SUBSCRIPTION_DETAIL} component={SubscriptionDetail}/>
                    <PrivateRoute path={Routes.SUBSCRIPTION_FEEDBACK} component={SubscriptionFeedBack}/>
                    <PrivateRoute path={Routes.PAYMENT_RESULT_CB} component={PaymentResultCb}/>
                    <PrivateRoute path={Routes.PAYMENT} component={Payment}/>
                    <PrivateRoute path={Routes.PAYMENT_RETRY} component={PaymentRetry}/>
                    <PrivateRoute path={Routes.PAYMENT_DUPLICATA} component={PaymentDuplicata}/>
                    <PrivateRoute path={Routes.BILLING_HISTORY} component={BillingHistory}/>
                    <PrivateRoute path={Routes.BILLING_DETAIL} component={BillingDetail}/>
                    <PrivateRoute path={Routes.LR_ACCOUNT} component={EditLr}/>
                    <PrivateRoute path={Routes.LOGOUT} component={Logout}/>

                    <SentryRoute path={Routes.CONFIRM_LR_REGISTRATION} component={ConfirmLRRegistration}/>
                    <SentryRoute path={Routes.CREATE_LR_ACCESS} component={CreateLRAccess}/>
                    <SentryRoute path={Routes.TRAVELLER} component={FormTravellerInfo}/>
                    <SentryRoute path={Routes.PUBLIC_DOCUMENTS} component={PublicDocuments}/>
                    <SentryRoute path={Routes.LR2_WISH_VALIDATION} component={ValidateLR2Wish}/>
                    {UtilityFunctions.isDev() && <SentryRoute path="/debug" component={DebugPage}/>}
                    <SentryRoute path="*" component={NotFoundPage}/>
                </Switch>
            </Suspense>
        </SettingsContextProvider>
    );
}

function Logout() {
    KeyCloakService.logout();

    return null;
}

export default RoutesComponent;
