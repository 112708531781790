// HelperPopinWrapper.js
import React from "react";
import HelperPopinRNA from "./HelperPopinRNA";
import HelperPopinDefault from "./HelperPopinDefault";

const HelperPopin = ({ isOpen, onClose }) => {
    const HelperPopinComponent =
        window._env_.REACT_APP_PROJECT === 'RNA'
            ? HelperPopinRNA
            : HelperPopinDefault;

    return <HelperPopinComponent isOpen={isOpen} onClose={onClose} />;
};

export default HelperPopin;
