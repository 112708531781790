import React, {FunctionComponent, ReactNode, useContext, useEffect, useMemo, useState} from "react";
import UtilityFunctions from "@/utils/UtilityFunctions";
import {Box} from "@mui/material";
import {BoxProps} from "@mui/material/Box/Box";
import useStateOpenClose from "@/hooks/useStateOpenClose";

const Header: FunctionComponent<{}> = () => {
    return null;
};

const Body: FunctionComponent<{}> = () => {
    return null;
};

const Footer: FunctionComponent<{ noPadding?: boolean }> = () => {
    return null;
};

export type PageType = React.FC<BoxProps> & {
    Header: typeof Header
    Body: typeof Body
    Footer: typeof Footer
}

const defaultPageContext = {
    isPageAvailable: false,
    setFooter: (f) => {},
    disableDesktopLimit: () => {},
    enableDesktopLimit: () => {},
};
const PageContext = React.createContext(defaultPageContext);


export function usePageContext() {
    const context = useContext(PageContext);

    if (context == null) {
        return defaultPageContext;
    }

    return context;
}

const Page: PageType = ({
    children,
    ...props
}) => {
    const childArray = UtilityFunctions.ensureArray<any>(children);
    const header = childArray.find(child => child?.type === Header);
    const body = childArray.find(child => child?.type === Body);
    const currentFooter = childArray.find(child => child?.type === Footer);
    const [footer, setFooter] = useState<ReactNode>();

    useEffect(() => {
        if (currentFooter) {
            setFooter(currentFooter.props.children);
        }
    }, [currentFooter]);

    const [isDesktopLimitDisabled, disableDesktopLimit, enableDesktopLimit] = useStateOpenClose(false);

    const pageContextValue = useMemo(() => ({
        isPageAvailable: true,
        setFooter: setFooter,
        disableDesktopLimit: disableDesktopLimit,
        enableDesktopLimit: enableDesktopLimit,
    }), [setFooter, disableDesktopLimit, enableDesktopLimit]);

    return (
        <PageContext.Provider value={pageContextValue}>
            <Box className={"page" + (isDesktopLimitDisabled ? " page-wide" : "")} {...props}>
                <div className={"page-content"}>
                    {header && <div className="page-header">
                        {header.props.children}
                    </div>}
                    {body && <div className="page-body">
                        {body.props.children}
                    </div>}
                </div>
                {footer &&
                    <div className="page-footer">
                        <Box p={currentFooter?.props.noPadding ? 0 : 2} className="page-footer-content">
                            {footer}
                        </Box>
                    </div>
                }
            </Box>
        </PageContext.Provider>
    );
};

Page.Header = Header;
Page.Body = Body;
Page.Footer = Footer;

export default Page;
