import KeyCloakService from "@/service/KeyCloakService";
import {useLoader} from "@/components/common/ui/LoaderContext";
import {LegalRepresentativeProvider} from "@/components/common/LegalRepresentativeContext";
import SentryRoute from "@/SentryRoute";

function PrivateRoute({
    component: Component,
    ...props
}) {
    const loader = useLoader();

    return (
        <SentryRoute {...props} render={props => {
            if (KeyCloakService.isAuthenticated()) {
                return (
                    <LegalRepresentativeProvider>
                        <Component {...props} loader={loader} key={props.location.state?.key}/>
                    </LegalRepresentativeProvider>
                );
            } else {
                KeyCloakService.login();
            }
        }}
        />
    );
}

export default PrivateRoute;