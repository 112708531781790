const Environment = {
    LOCAL: "loc",
    INTEG: "dev",
    RECETTE: "rec",
    PRE_PROD: "preprod",
    PROD: "prod",
};

const currentEnvironment = window._env_.ENVIRONMENT;

const isLocal = currentEnvironment === Environment.LOCAL;
const isProd = currentEnvironment === Environment.PROD;

export {
    Environment,
    currentEnvironment,
    isProd,
    isLocal,
};

