import {useCallback, useState} from "react";

/**
 * Crée un state avec deux fonctions utilitaire open/close qui vont respectivement set le state à true/false
 * @param  {boolean}                               initialState
 * @return {[boolean, VoidFunction, VoidFunction]}
 */
export default function useStateOpenClose(initialState: boolean): [boolean, VoidFunction, VoidFunction] {
    const [isOpen, setIsOpen] = useState(initialState);

    const open = useCallback(() => setIsOpen(true), []);
    const close = useCallback(() => setIsOpen(false), []);

    return [isOpen, open, close];
}